import React from "react";
import { useNativePlatform } from "../../hooks";
import { Camera } from "@capacitor/camera";
import { Message } from "@brenger/react";

export const CameraPermissionMessage: React.FC<{ className?: string }> = ({ className }) => {
  const [isGranted, setIsGranted] = React.useState(true);
  const isNative = useNativePlatform();

  React.useEffect(() => {
    if (!isNative) return;
    Camera.checkPermissions().then((result) => {
      setIsGranted(result.camera === "granted" && result.photos === "granted");
    });
  }, [isNative]);

  if (isGranted) return null;

  return (
    <Message type="alert" className={className}>
      De app zal vragen om toestemming zodra je een foto toevoegt. Je kunt ook handmatig{" "}
      <a
        href={"#"}
        className="underline"
        onClick={(e) => {
          e.preventDefault();
          Camera.requestPermissions();
        }}
      >
        toestemming geven
      </a>
    </Message>
  );
};
